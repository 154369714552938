/**
=========================================================
* Soft UI Dashboard React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { Grid } from "@mui/material";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import axios from "axios";
import { createBrowserHistory } from "history";

const history = createBrowserHistory({ forceRefresh: true });
function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);
  const [username, setUserName] = useState();
  const [pwrd, setPwrd] = useState();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const signInClicked = (event) => {
    event.preventDefault();

    const postData = {
      emailAddress: username,
      password: pwrd,
    };

    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    console.log("PostData: ", postData);
    axios
      .post("https://dttapi.devforhealth.com/api/Users/login", postData, axiosConfig)
      .then((response) => {
        console.log("response: ", response);
        if (response.data) {
          console.log(response);
          localStorage.setItem("dtt_token", response.data.token);
          localStorage.setItem("dtt_user", response.data);
          history.push("/PatientSearch");
          window.location.reload(false);
        } else {
          alert("Invalid Username and Password");
        }
      });
  };
  return (
    <Grid container justify="center" justifyContent="center">
      <SuiBox
        component="form"
        justifyItems="center"
        alignItems="center"
        sx={{ width: 500, marginTop: 10 }}
      >
        <SuiBox mb={2}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SuiTypography>
          </SuiBox>
          <SuiInput
            type="email"
            placeholder="Email"
            onChange={(e) => setUserName(e.target.value)}
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SuiTypography>
          </SuiBox>
          <SuiInput
            type="password"
            placeholder="Password"
            onChange={(e) => setPwrd(e.target.value)}
          />
        </SuiBox>
        <SuiBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SuiTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </SuiTypography>
        </SuiBox>
        <SuiBox mt={4} mb={1}>
          <SuiButton variant="gradient" color="info" onClick={signInClicked} fullWidth>
            sign in
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </Grid>
  );
}

export default SignIn;
